// api.ts : API client (axios) and JWT token header management

import axios from 'axios';
import { refreshToken } from './authService';
import { clearToken, getAccessToken, getRefreshToken, setToken } from '@/utils/tokenStorage';

function getBackendUrl() {
  const domain = window.location.hostname;
  if (domain === 'app.gravia.io') {
    //return 'https://4wn3xvx2eg.eu-west-3.awsapprunner.com'; // No need to specify port
    return 'https://api.gravia.io'
  }
  else if (domain === 'app-staging.gravia.io') {
    return 'https://api-staging.gravia.io'
  }
  return 'http://localhost:5001'; // Default for development
}

const baseUrl = getBackendUrl();


// CARE !!!!! The CompletionService uses its own client (not axios cause needing to manage streaming response) !!!!

// Axios instance for common configuration
const apiClient = axios.create({
  baseURL: baseUrl, // Replace with your actual backend URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Separate Axios instance for refreshing tokens
const tokenRefreshClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Request interceptor to add the token
apiClient.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


// Response interceptor to handle token refresh
apiClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshTokenValue = getRefreshToken();
    //console.log(refreshTokenValue);
    if (refreshTokenValue) {
      try {
        const { access_token, refresh_token } = await refreshToken(refreshTokenValue);
        setToken(access_token, refresh_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${ access_token }`;

        // Emit a custom event (listened at the app level: need to update redux data on user)
        window.dispatchEvent(new CustomEvent('tokenRefreshed', { detail: { access_token, refresh_token } }));

        return apiClient(originalRequest);
      } catch (refreshError) {
        clearToken();
        window.dispatchEvent(new CustomEvent('tokenRefreshFail'));
        // Redirect to login page
        window.location.href = '/login'; // Redirect to login
        return Promise.reject(refreshError);
      }
    }
    else {
      window.location.href = '/login'; // Redirect to login
    }
  }
  return Promise.reject(error);
});


export { apiClient, tokenRefreshClient, baseUrl }